import { type FC } from "react";

import { classnames, toSlug } from "@vgno/utils";

import { Background } from "./Background";

import styles from "./Sidequests.module.css";

const links = [
  {
    title: "Ordstjernen",
    description: "Gjett ordene med syv bokstaver",
    url: "https://www.vg.no/spill/ordstjernen",
    fill: "var(--accent)",
    image: "https://imbo.vgc.no/s/967yjxG",
  },
  {
    title: "Quiz",
    description: "Ukentlig quiz - syv nye hver fredag",
    url: "/quiz",
    fill: "var(--orange)",
    image: "https://imbo.vgc.no/s/WstUk5R",
  },
  {
    title: "Kryssord",
    description: "Finn synonymer og fyll ut feltene",
    url: "/kryssord",
    fill: "var(--pink)",
    image: "https://imbo.vgc.no/s/xKYxq3G",
  },
  {
    title: "Tegneserier",
    description: "Alle tegneseriene våre samlet",
    url: "https://www.vg.no/tegneserier",
    fill: "var(--teal)",
    image: "https://imbo.vgc.no/s/6Wdmqss",
  },
];

interface Props {
  className?: string;
  primaryPosition: number;
}

export const Sidequests: FC<Props> = ({ className, primaryPosition }) => (
  <track-element
    data-track-element-type="sidequest"
    data-track-name="Sidequests list"
    data-track-impression
    data-track-target-type="ExternalContent"
    data-track-id="sidequests:list"
    data-track-primary-position={primaryPosition}
  >
    <div className={classnames(styles.sidequests, className)}>
      <ul>
        {links.map((link, index) => (
          <li key={link.url}>
            <a
              href={link.url}
              data-track-bundle-position={index + 1}
              data-track-image-url={link.image}
              data-track-name={link.title}
              data-track-target={link.url}
              data-track-id={`sidequests:${toSlug(link.title)}`}
            >
              <Background fill={link.fill}>
                <img src={link.image} alt={link.title} loading="lazy" />
              </Background>
              <div className={styles.text}>
                <h3 className="label-medium">{link.title}</h3>
                <p className="label-small">{link.description}</p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  </track-element>
);
