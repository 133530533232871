import { useStore } from "@nanostores/react";
import { Fragment, useEffect, useRef } from "react";

import { Advert, updateAdvertory } from "@vgno/adverts";
import { Teaser, type TeaserProps } from "@vgno/teasers/Teaser";
import { getReadtimeMinutes } from "@vgno/utils";
import VideoList from "@vgno/widgets/VideoList";

import { $teaserStore, fetchTeasers } from "../utils/fetchFront";

import { BuySubscription } from "./BuySubscription";
import { Sidequests } from "./Sidequests/Sidequests";

import type { Article } from "@vgno/article";
import type { AdConfig } from "@vgno/astro/@types";
import type { Video } from "vg";

interface Props {
  advertsInsertionRules?: AdConfig["insertionRules"];
  entertainmentVideos?: Video[];
  isApp?: boolean;
  deviceType: "desktop" | "mobile" | "tablet";
}

export const Feed = ({
  advertsInsertionRules,
  entertainmentVideos,
  isApp,
  deviceType,
}: Props) => {
  const sentinel = useRef<HTMLDivElement | null>(null);
  const { bundles, isFinished, isLoading } = useStore($teaserStore);

  // Intersection Observer for loading more teasers
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          fetchTeasers();
        }
      },
      {
        root: null,
        rootMargin: "100%",
        threshold: 0.1,
      },
    );

    const currentSentinel = sentinel.current;
    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [bundles.length]);

  useEffect(() => {
    const sectionFeed = document.querySelector('section[role="feed"]');

    if (sectionFeed) {
      sectionFeed.setAttribute("aria-busy", isLoading ? "true" : "false");
    }

    if (!isLoading) {
      updateAdvertory({}, { contextSelector: "#feed" });
    }
  }, [isLoading]);

  const widgets = {
    1: !isApp && <BuySubscription deviceType={deviceType} />,
    3: entertainmentVideos && (
      <VideoList
        className="widget"
        data={entertainmentVideos}
        data-track-element-type="Upcoming Broadcasts"
        data-track-id="pluss-entertainment-videos"
        data-track-impression
        data-track-name="Upcoming Broadcasts"
        label="category"
        title="VG+ Underholdning"
        vgtvUrl="https://tv.vg.no/kategori/100324/underholdning"
      />
    ),
    6: <Sidequests className="widget" primaryPosition={6} />,
  };

  return (
    <section
      data-track-curate-context="plussseksjon"
      style={{ display: "contents" }}
    >
      {bundles.map((bundle, bundleIndex) => {
        if (bundle.length === 0) return undefined;

        const advert = advertsInsertionRules?.find(
          (advert) => advert.index === bundleIndex,
        );

        return (
          <Fragment key={bundle[0].id}>
            {advert && (
              <Advert
                placementId={advert.placementId}
                subtype={advert.subtype}
              />
            )}
            {widgets[bundleIndex]}
            {bundle.map((teaser, teaserIndex) => {
              const teaserSize = getTeaserSize(
                teaser,
                bundleIndex,
                teaserIndex,
              );

              const video = teaser.components?.find(
                (component) => component.type === "video",
              );

              let duration: number | undefined;
              const isVgtvVideoTeaser =
                teaser.contentType === "video" &&
                video &&
                video.type === "video";

              if (isVgtvVideoTeaser) {
                duration = video.videoAsset.duration;
              }

              const isOverlayVariant =
                teaserSize !== "small" && bundleIndex % 2 === 0;

              return (
                <track-element
                  data-track-primary-position={bundleIndex + 1}
                  data-track-bundle-position={teaserIndex + 1}
                  data-track-target-newsroom={teaser.newsroom}
                  data-track-element-size={teaserSize}
                  data-track-id={`teaser:${teaser.id}`}
                  data-track-element-type="Teaser"
                  data-track-target-type="Article"
                  data-track-click-intent="View"
                  data-track-image-url={
                    teaser.promotionContent?.imageAsset?.urls?.[0]?.url
                  }
                  data-track-impression
                  data-track-layout-name={getLayoutName(
                    teaserSize,
                    isOverlayVariant,
                    teaser.customProperties.presentation,
                  )}
                  data-track-name={teaser.title.value}
                  key={teaser.id}
                >
                  <Teaser
                    alt={teaser.promotionContent?.alternateText?.value}
                    authors={teaser.authors}
                    contentType={teaser.contentType}
                    duration={duration}
                    fetchpriority={bundleIndex === 0 ? "high" : "low"}
                    headlineType="h2"
                    imageAsset={teaser.promotionContent?.imageAsset}
                    loading={bundleIndex >= 1 ? "lazy" : "eager"}
                    overlay={isOverlayVariant && !isVgtvVideoTeaser}
                    paywall={
                      Boolean(teaser?.customProperties?.paywall) ?? false
                    }
                    readTimeMinutes={getReadtimeMinutes(teaser.wordCount)}
                    size={teaserSize}
                    timestamp={teaser.changes.published}
                    title={
                      teaser.customProperties.frontPageTitle ||
                      teaser.title.value
                    }
                    type={teaser.customProperties.presentation}
                    url={teaser.links.canonicalUrl}
                  />
                </track-element>
              );
            })}
          </Fragment>
        );
      })}
      {!isFinished && <div className="spinner" ref={sentinel} />}
    </section>
  );
};

function getTeaserSize(
  teaser: Article["teaser"],
  bundleIndex: number,
  teaserIndex: number,
) {
  return teaser.characteristics.hotness >= 60 ||
    (bundleIndex === 0 && teaserIndex === 0)
    ? "medium"
    : "small";
}

function getLayoutName(
  teaserSize: TeaserProps["size"],
  isOverlayVariant: boolean,
  type: TeaserProps["type"],
) {
  // Large size is used for features
  const size = type === "feature" ? "large" : teaserSize;

  return `${size}-${size === "large" || (isOverlayVariant && size === "medium") ? "overlay" : "without-overlay"}`;
}
