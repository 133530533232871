import styles from "./Sidequests.module.css";

export const Background = ({ fill, children }) => {
  return (
    <div className={styles.background}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="80"
        viewBox="0 0 104 80"
        fill="none"
      >
        <path
          d="M76.1892 0H0V80H76.136C78.9798 80 81.6156 78.5294 83.0818 76.1248L102.839 43.7221C104.402 41.1597 104.386 37.9512 102.797 35.4043L83.093 3.80702C81.6176 1.44115 79.0042 0 76.1892 0Z"
          fill={fill}
        ></path>
      </svg>
      {children}
    </div>
  );
};
